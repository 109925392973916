import React, { useState, useEffect } from 'react';
import '../css/Voyscalls.css';
import Modal from './Modal'; 

const VoysCalls = () => {
  const [scheduleType, setScheduleType] = useState('daily');
  const [dayOfWeek, setDayOfWeek] = useState('Monday');
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [progressLogs, setProgressLogs] = useState([]);   
  let intervalId = null; // Declare interval ID outside to manage progress updates

  useEffect(() => {
    // Fetch the existing schedules when the component mounts
    const fetchSchedules = async () => {
      try {
        const response = await fetch('/api/v1/schedules');
        if (response.ok) {
          const data = await response.json();
          setSchedules(data);
        } else {
          throw new Error('Failed to fetch schedules');
        }
      } catch (error) {
        console.error(error);
        alert('Failed to get schedules');
      } finally {
        setLoading(false);
      }
    };

    fetchSchedules();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const schedule = {
      type: scheduleType,
      hour,
      minute,
      dayOfWeek: scheduleType === 'weekly' ? dayOfWeek : null,
    };

    // Send the scheduling information to the backend
    const response = await fetch('/api/v1/schedule', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(schedule),
    });

    if (response.ok) {
      alert('Schedule set successfully!');
      window.location.reload(); // Reload to refresh the schedule list
    } else {
      alert('Failed to set the schedule.');
    }
  };

  const handleDelete = async (id) => {
    const response = await fetch(`/api/v1/schedules/${id}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      alert('Schedule deleted successfully!');
      setSchedules(schedules.filter((schedule) => schedule.id !== id));
    } else {
      alert('Failed to delete the schedule.');
    }
  };

  const updateProgress = (message) => {
    setProgressLogs((prevLogs) => [...prevLogs, message]);
  };

  const handleDownloadNow = async () => {
    if (!startDate || !endDate) {
      alert('Please select both a start date and an end date.');
      return;
    }

    setIsModalOpen(true);
    setProgressLogs([]);

    try {
      const response = await fetch('/api/v1/download-now', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ startDate, endDate }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      // Interval to show "Still in progress" every 30 seconds
      intervalId = setInterval(() => {
        updateProgress("We are still in progress downloading and uploading the calls, please wait.");
      }, 30000);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        chunk.split('\n').forEach((line) => {
          if (line) {
            const parsed = JSON.parse(line);
            if (parsed.progress) {
              updateProgress(parsed.progress);
            }
          }
        });
      }
    } catch (error) {
      updateProgress(`Error: ${error.message}`);
    } finally {
      clearInterval(intervalId); // Clear the interval after process ends
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="schedule-container">
      <form onSubmit={handleSubmit} className="schedule-form">
        <div className="form-group">
          <label>Schedule:</label>
          <select
            value={scheduleType}
            onChange={(e) => setScheduleType(e.target.value)}
          >
            <option value="daily">Every Day</option>
            <option value="weekly">Every Week on Specific Day</option>
          </select>
        </div>

        {scheduleType === 'weekly' && (
          <div className="form-group">
            <label>Day of the Week:</label>
            <select
              value={dayOfWeek}
              onChange={(e) => setDayOfWeek(e.target.value)}
            >
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
        )}

        <div className="form-group">
          <label>Hour:</label>
          <input
            type="number"
            value={hour}
            onChange={(e) => setHour(e.target.value)}
            placeholder="Enter hour (0-23)"
            required
          />
        </div>

        <div className="form-group">
          <label>Minute:</label>
          <input
            type="number"
            value={minute}
            onChange={(e) => setMinute(e.target.value)}
            placeholder="Enter minute (0-59)"
            required
          />
        </div>

        <div className="form-group">
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="schedule-button">Set Schedule</button>
        <button type="button" onClick={handleDownloadNow} className="schedule-button">Download Now</button>
      </form>

      <div className="schedule-list">
        <h2>Scheduled Jobs</h2>
        {loading ? (
          <p>Loading...</p>
        ) : schedules.length > 0 ? (
          <table className="scheduled-jobs-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Next Execution</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {schedules.map((schedule) => (
                <tr key={schedule.id}>
                  <td>{schedule.description}</td>
                  <td>{new Date(schedule.nextExecution).toLocaleString()}</td>
                  <td><button className="delete-button" onClick={() => handleDelete(schedule.id)}>Delete</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>There are no scheduled jobs</p>
        )}
      </div>
      

      {/* Modal to show download progress */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div>
          <h2>Download in progress, please wait</h2>
          <div className="progress-log">
            {progressLogs.map((log, index) => (
              <p key={index} style={{ marginBottom: '10px' }}>{log}</p>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VoysCalls;
